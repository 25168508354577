import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import { dark, royal } from 'constants/theme';
import IO from 'components/common/io';

export const Container = styled.div`
  padding: 3.2rem;
  text-align: center;
  color: #fff;
  transition: background-color 0.3s ease;
  background-color: ${({ isVisible }) => (isVisible ? dark : royal)};
  p {
    font-size: 11px;
    margin: 10px 10px 0px 10px;
    color: #8372c6;
  }
`;
const SocialLinks = styled.div`
  color: #fff;
  text-align: center;
  font-size: 18px;
  a {
    color: #fff;
  }
`;
const SocialIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
  margin: 0px 8px 10px 8px;
`;
const FooterLink = styled.a`
  text-decoration: none;
  color: white;
  &:hover {
    color: ${royal};
  }
`;

const Footer = ({ data }) => (
  <IO>
    {({ isVisible }) => (
      <Container isVisible={isVisible}>
        <SocialLinks>
          {data.facebook && (
            <a href={data.facebook}>
              <SocialIcon icon={faFacebookF} />
            </a>
          )}
          {data.twitter && (
            <a href={data.twitter}>
              <SocialIcon icon={faTwitter} />
            </a>
          )}
          {data.linkedin && (
            <a href={data.linkedin}>
              <SocialIcon icon={faLinkedinIn} />
            </a>
          )}
        </SocialLinks>
        <p>2020 © Flamefinch Partners</p>
        <p>All Rights Reserved</p>
        <p>
          Website by
          <FooterLink
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/company/adaptile/"
          >
            {' '}
            Adaptile
          </FooterLink>
        </p>
      </Container>
    )}
  </IO>
);

Footer.propTypes = {
  data: PropTypes.object.isRequired,
};

const FooterWithQuery = props => (
  <StaticQuery
    query={graphql`
      query FooterQuery {
        homeYaml {
          facebook
          twitter
          linkedin
        }
      }
    `}
    render={({ homeYaml }) => <Footer data={homeYaml} {...props} />}
  />
);

export default FooterWithQuery;
