export const whiteText = '#FFFFFF';
export const lightText = '#9396CF';
export const faintText = '#D9E6FD';
export const fainterText = '#E5E8FF';
export const washedText = '#474CB0';
export const purplyText = '#8868FF';
export const darkText = '#342A28';
export const royalText = '#2E3EFF';

export const white = '#FFFFFF';
export const light = '#F4F8FF';
export const dark = '#2B1972';
export const royal = '#5A30FF';

export default {
  whiteText,
  lightText,
  faintText,
  fainterText,
  washedText,
  darkText,
  royalText,
  white,
  light,
  dark,
  royal,
  purplyText,
};
