import { createGlobalStyle } from 'styled-components';
import { royalText } from 'constants/theme';
import MEDIA from 'helpers/mediaTemplates';

import TiemposHeadlineSemiboldWOFF2 from 'fonts/TiemposHeadlineWeb-Semibold.woff2';
import TiemposHeadlineSemiboldWOFF from 'fonts/TiemposHeadlineWeb-Semibold.woff';
import TiemposHeadlineSemiboldEOT from 'fonts/TiemposHeadlineWeb-Semibold.eot';
import TiemposHeadlineSemiboldItalicWOFF2 from 'fonts/TiemposHeadlineWeb-SemiboldItalic.woff2';
import TiemposHeadlineSemiboldItalicWOFF from 'fonts/TiemposHeadlineWeb-SemiboldItalic.woff';
import TiemposHeadlineSemiboldItalicEOT from 'fonts/TiemposHeadlineWeb-SemiboldItalic.eot';

export default createGlobalStyle`
  @font-face {
    font-family: "TiemposHeadline";
    font-style: normal;
    font-weight: bold;
    src: local("Tiempos Headline Semibold"),
      local("TiemposHeadline-Semibold"),
      url(${TiemposHeadlineSemiboldWOFF2}) format("woff2"),
      url(${TiemposHeadlineSemiboldWOFF}) format("woff"),
      url(${TiemposHeadlineSemiboldEOT}) format("eot");
  }

  @font-face {
    font-family: "TiemposHeadline";
    font-style: italic;
    font-weight: bold;
    src: local("Tiempos Headline Semibold Italic"),
      local("TiemposHeadline-SemiboldItalic"),
      url(${TiemposHeadlineSemiboldItalicWOFF2}) format("woff2"),
      url(${TiemposHeadlineSemiboldItalicWOFF}) format("woff"),
      url(${TiemposHeadlineSemiboldItalicEOT}) format("eot");
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  html {
    font-size: 62.5%;
  }

  body {
    font-family: "Nunito Sans",-apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
    line-height: 1;
    font-size: 1.8rem;
    color: #000;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    -webkit-font-feature-settings: "pnum";
    font-feature-settings: "pnum";
    font-variant-numeric: proportional-nums;
  }

  [data-reach-dialog-content] {
    border-radius: 12px;
    width: 60vw !important;
    max-width: 600px !important;
    ${MEDIA.TABLET`
      width: 80vw !important;
    `};

  }

  h1, h2, h3, h4, h5 {
    font-family: "TiemposHeadline";
    font-weight: bold;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  pre {
    display: block;
    padding: 2rem;
    margin-top: 4rem;
    overflow: auto;
    font-size: 85%;
    line-height: 1.45;
    border-radius: 5px;
    color: ${royalText};
    border: 1px solid #ddd;
    font-family: "SFMono-Regular",Consolas,"Liberation Mono",Menlo,Courier,monospace;
  }

  video {
    max-width: 100%;
  }

  p {
    margin-bottom: 2rem;
  }
`;
